import "../../css/smallCard.css";
const SmallCard = ({ title, description, imgUrl, link }) => {
  return (
    <div className="small-card-container">
      <div className="link" href={link}>
        <img
          src={imgUrl}
          width="80"
          height="80"
          alt={title}
          data-aos="fade-up"
        />
        <br />
        <h3 data-aos="fade-up">{title}</h3>
      </div>
      <span className="description" data-aos="fade-up">
        {description}
      </span>
    </div>
  );
};

export default SmallCard;
