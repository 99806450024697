import React from "react";
import { NavLink } from "react-router-dom";

const Sondage = () => {
  return (
    <div className="container row post-page">
      <article className="col-md-12">
        <NavLink to="/" className="link-left-arrow">
          Retourner à l'accueil
        </NavLink>
        <h2 className="post-title mrt-4">Sondages sur sites</h2>
        <p>
          Bienvenue sur notre site dédié aux sondages géotechniques pour l'étude
          géotechnique. Nous sommes spécialisés dans les quatre principales
          techniques de sondage utilisées dans ce domaine : le sondage carotté,
          le sondage pénétrométrique, l'essai pressiométrique et l'équipement
          piézométrique.
          <br /> <br />
          <b>Le sondage carotté</b> est une méthode couramment utilisée pour
          collecter des échantillons de sol en profondeur. Grâce à des foreuses
          spéciales, nous sommes en mesure d'extraire des carottes de sol de
          différentes profondeurs. Ces carottes sont ensuite analysées en
          laboratoire pour évaluer les propriétés physiques et mécaniques du
          sol, fournissant ainsi des informations essentielles pour la
          conception de projets géotechniques.
          <br /> <br />
          <b>Le sondage pénétrométrique</b> est une technique qui permet de
          mesurer la résistance du sol en fonction de la profondeur. Un
          pénétromètre est utilisé pour enfoncer un cône dans le sol, et les
          données de résistance sont enregistrées. Cette méthode est efficace
          pour évaluer la compacité et la cohésion du sol, ce qui est crucial
          pour la conception des fondations et des ouvrages souterrains.
          <br /> <br />
          <b>L'essai pressiométrique</b> est une méthode permettant de
          caractériser les propriétés mécaniques du sol en mesurant sa
          résistance à l'expansion. Un dispositif pressiométrique est inséré
          dans un trou de forage et applique une pression croissante sur les
          parois. Les données obtenues permettent de déterminer les modules de
          déformation du sol et de calculer des paramètres géotechniques
          importants tels que la capacité portante et la stabilité des
          fondations.
          <br /> <br />
          Enfin, <b>l'équipement piézométrique</b> est utilisé pour mesurer les
          variations de pression d'eau dans le sol. Ces mesures sont
          essentielles pour comprendre le comportement hydraulique des sols et
          la distribution des nappes phréatiques. Grâce à des piézomètres
          installés à différentes profondeurs, nous pouvons surveiller en temps
          réel les fluctuations des niveaux d'eau et prendre des décisions
          éclairées pour la gestion des risques et la conception de systèmes de
          drainage.
          <br /> <br />
          Que vous ayez besoin d'évaluer la faisabilité d'un projet de
          construction, de caractériser un site pour une étude géotechnique
          approfondie ou de surveiller les conditions géotechniques d'un site
          existant, nos services de sondage géotechnique vous fourniront les
          données nécessaires pour prendre des décisions éclairées et garantir
          la sécurité et la stabilité de vos projets. Faites-nous confiance pour
          des sondages géotechniques précis, fiables et adaptés à vos besoins
          spécifiques.
        </p>
      </article>
    </div>
  );
};

export default Sondage;
