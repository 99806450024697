import React from "react";
import { NavLink } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <div className="row post-page">
      <article className="col-md-12">
        <NavLink to="/" className="link-left-arrow">
          Retourner à l'accueil
        </NavLink>
        <h1 className="post-title ">LES MENTIONS LÉGALES</h1>
        <div className="post-underline"></div>
        <h3 className="post-block-title">
          1. Conditions générales d'utilisation du site et des services proposés
        </h3>
        <p>
          L'utilisation du site implique l'acceptation pleine et entière des
          conditions générales d'utilisation décrites ci-après. Ces conditions
          d'utilisation peuvent être modifiées ou complétées à tout moment, il
          est donc recommandé de les consulter régulièrement.
        </p>
        <hr />
        <h3 className="post-block-title">2. Éditeur du site</h3>
        <ul>
          <li>Raison sociale : [Nom de votre entreprise]</li>
          <li>Adresse : [Adresse de votre entreprise]</li>
          <li>Téléphone : [Numéro de téléphone de votre entreprise]</li>
          <li>Email : [Adresse email de votre entreprise]</li>
          <li>SIRET : [Numéro SIRET de votre entreprise]</li>
          <li>
            Responsable de la publication : [Nom du responsable de la
            publication]
          </li>
        </ul>
        <hr />
        <h3 className="post-block-title">
          3. Propriété intellectuelle et contrefaçons
        </h3>
        <p>
          Le contenu de ce site (textes, images, graphismes, logos, etc.) est la
          propriété exclusive de SOL 4D ou fait l'objet d'une autorisation
          d'utilisation. Toute reproduction, modification, publication ou
          adaptation, en tout ou en partie, est strictement interdite sans
          autorisation préalable de SOL 4D.
        </p>
        <hr />
        <h3 className="post-block-title">4. Responsabilité</h3>
        <p>
          SOL 4D s'efforce de mettre à jour régulièrement le site et de fournir
          des informations précises. Cependant, SOL 4D décline toute
          responsabilité en cas d'omissions, d'inexactitudes ou de défauts dans
          la mise à jour des informations, qu'ils soient de son fait ou du fait
          de tiers partenaires.
          <br />
          SOL 4D ne peut être tenu responsable des dommages directs ou indirects
          résultant de l'utilisation du site, de l'accès à des liens externes,
          ou de l'utilisation de données personnelles fournies par les
          utilisateurs.
        </p>
        <hr />
        <h3 className="post-block-title">
          5. Collecte et traitement des données personnelles
        </h3>
        <p>
          SOL 4D collecte et traite les données personnelles conformément à sa
          politique de confidentialité. Pour en savoir plus sur la collecte et
          le traitement de vos données personnelles, veuillez consulter notre
          politique de confidentialité sur le site.
        </p>
        <hr />
        <h3 className="post-block-title">
          6. Droit applicable et juridiction compétente
        </h3>
        <p>
          Tout litige en relation avec l'utilisation du site est soumis au droit
          français. En cas de litige, les tribunaux compétents de Paris seront
          seuls compétents.
        </p>
        <hr />
        <h3 className="post-block-title">8. Contact</h3>
        <p>
          Pour toute question ou demande concernant ces mentions légales,
          veuillez nous contacter à l'adresse email indiquée ci-dessus.
        </p>
      </article>
    </div>
  );
};

export default TermsOfUse;
