import HeroPage from "./HeroPage";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Moyens from "./Moyens";
import Testimonial from "./Testimonial";

const Home = ({
    anchorHomeRef,
    anchorAboutUsRef,
    anchorServicesRef,
    anchorMoyensRef,
    anchorTestimonialRef,
    anchorContactUsRef
  }) => {
    return (
        <>
            <HeroPage anchorHomeRef={anchorHomeRef} anchorContactUsRef={anchorContactUsRef} />
            <AboutUs anchorAboutUsRef={anchorAboutUsRef} />
            <Services anchorServicesRef={anchorServicesRef} />
            <Moyens anchorMoyensRef={anchorMoyensRef} />
            <Testimonial anchorTestimonialRef={anchorTestimonialRef} />
        </>
    )
}


export default Home;