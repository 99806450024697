import { useEffect } from "react";
/* Import external packages */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

/* Import CSS Files*/
import "../css/hero.css";

const HeroPage = ({ anchorHomeRef, anchorContactUsRef }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section
      id="accueil"
      ref={anchorHomeRef}
      className="block block--secondary block--skewed-left hero hero--container"
    >
      <div className="container grid--container grid grid--1x2 grid--gap-3">
        <header className="block__header hero__content">
          <h1 className="block__heading" data-aos="fade-up">
            Études de sol sous 15 jours
          </h1>
          <div className="box">
            <p className="p--light" data-aos="fade-up">
              Vous vendez votre terrain ?
            </p>
            <p className="p--light" data-aos="fade-up">
              Vous construisez ou agrandissez votre maison ?
            </p>
            <p className="p--light" data-aos="fade-up">
              Obtenez votre devis gratuit pour une étude G1 ou G2
            </p>
            <NavLink
              to="#contact"
              onClick={() => {
                if (anchorContactUsRef != null && anchorContactUsRef.current) {
                  anchorContactUsRef.current.scrollIntoView({
                    behavior: "smooth",
                  });
                }
              }}
            >
              <button className="btn btn--accent btn--stretched btn--devis">
                Devis en ligne
              </button>
            </NavLink>
          </div>
        </header>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/GlobaleImages%2Fsol2.jpeg?alt=media&token=f404090f-37c8-4554-9cf2-bd7465c5cc2d"
          alt="Couches de sol"
          data-aos="fade-up"
        ></img>
        {/* <iframe
          src="https://www.youtube.com/embed/UzBqpdXAz74"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe> */}
        <div></div>
        <div className="socialMedia-container">
          <p className="p--light">suivez-nous sur : </p>
          <div className="socialMedia--icons">
            <a
              href="/"
              className="icon-container icon icon icon--primary icon--item"
              data-aos="fade-left"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              href="/"
              className="icon-container icon icon--primary icon--item"
              data-aos="fade-left"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroPage;
