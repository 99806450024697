/* Constantes pour la partie services */
export const SERVICES = [
  {
    Id: "SONDAGE",
    ImageUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/ServicesImages%2Fforage_environnement.jpg?alt=media&token=2614d584-85f0-4608-9cae-b4f5031de943",
    Title: "Sondages sur site",
    ShortDescriptions: [
      "Sondage carotté",
      "Sondage pénétrométrique",
      "Essai pressiométrique",
      "Équipement piézométrique",
    ],
    Link: "sondage",
  },
  {
    Id: "LABORATOIRE",
    ImageUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/ServicesImages%2Ftriaxial.png?alt=media&token=3bc47e96-b049-4235-bb1c-2683aa9c6812",
    Title: "Essais de Laboratoire",
    ShortDescriptions: [
      "Analyse granulométrique",
      "Teneur en eau",
      "Essai triaxial",
      "Essai odométrique",
    ],
    Link: "laboratoire",
  },
  {
    Id: "INGENIEURIE-SOL",
    ImageUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/ServicesImages%2FSECTEUR_BTP_440726068_1920px.jpeg?alt=media&token=df6bc59a-f583-49ac-8e18-602e1f03d7cd",
    Title: "Ingénierie des sols",
    ShortDescriptions: [
      "Modèle géologique",
      "Conception de fondation",
      "Stabilité de talus",
      "Justification géotechnique",
    ],
    Link: "ingenieurie-sol",
  },
  {
    Id: "INTERACTION-SOL-STRUCTURE",
    ImageUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/ServicesImages%2FISS-BG.jpg?alt=media&token=467db392-769b-4252-8cae-31000dde9337",
    Title: "Interaction sol structure",
    ShortDescriptions: [
      "Analyse aux élements finis",
      "Estimation des tassements",
      "Module de réaction de sol",
      "Calcul non-linéaire",
    ],
    Link: "interaction-sol-structure",
  },
];

/* Export pour la partie Logiciels */
export const SOFTWARES = [
  {
    title: "Foxta v4",
    description: "Dimensionnement des fondations",
    imgUrl:
      "https://www.terrasol.fr/wp-content/uploads/2017/12/Icne_foxta_v3.png",
    link: "https://www.terrasol.fr/catalogue/foxta-v4",
  },
  {
    title: "K-Réa v4",
    description: "Dimensionnement des écrans de soutènement",
    imgUrl: "https://www.terrasol.fr/wp-content/uploads/2017/12/Icne_k-rea.png",
    link: "https://www.terrasol.fr/catalogue/k-rea-v4",
  },
  {
    title: "Talren v6",
    description: "Stabilité des ouvrages géotechniques",
    imgUrl:
      "https://www.terrasol.fr/wp-content/uploads/2021/08/Icone_Talren-v01_80_80_px.png",
    link: "https://www.terrasol.fr/catalogue/talren-v6",
  },
  {
    title: "Plaxis 2D",
    description: "Logiciel de calcul par éléments finis en 2D",
    imgUrl:
      "https://www.terrasol.fr/wp-content/uploads/2017/12/PLAXIS_CONNECTEdition_Icon_PLAXIS_2D.png",
    link: "https://www.terrasol.fr/catalogue/plaxis-2d",
  },
  {
    title: "Plaxis 3D",
    description: "Logiciel de calcul par éléments finis en 3D",
    imgUrl:
      "https://www.terrasol.fr/wp-content/uploads/2017/12/PLAXIS_CONNECTEdition_Icon_PLAXIS_3D.png",
    link: "https://www.terrasol.fr/catalogue/plaxis-3d",
  },
  {
    title: "AutoCAD",
    description: "Réalisation des dessins en 2D et 3D",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/MoyensImages%2FLogiciels%2FAutodesk-Autocad-150x150.png?alt=media&token=4ee076c3-a242-40fc-9451-663e79a374c5",
    link: "https://www.autodesk.fr/products/autocad/overview?term=1-YEAR&tab=subscription",
  },
  {
    title: "Revit",
    description: "Modélisation des maquettes en 3D (BIM)",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/MoyensImages%2FLogiciels%2FAutodesk-Revit-icon.png?alt=media&token=3546668f-9bde-4e56-850b-20eafd2c5381",
    link: "https://www.autodesk.fr/products/revit/overview?term=1-YEAR&tab=subscription",
  },
  {
    title: "Python",
    description: "Langage de programation et automatisation des tâches",
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/MoyensImages%2FLogiciels%2FPython-logo-notext.svg.png?alt=media&token=1e3d9831-b482-4c92-b01c-0edbf64781b8",
    link: "https://www.python.org/",
  },
];

/* Export pour la partie moyens Materiels */
export const MATERIALS = [
  {
    Icon: "",
    Image: "Laboratory-material.jpeg",
    Title: "Matériels de laboratoire",
    Description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem, nam culpa. Laboriosam ea reprehenderit quisquam nulla eligendi adipisci, eveniet magni, architecto rem eius accusamus exercitationem necessitatibus.",
  },
  {
    Icon: "",
    Image: "Forage-machine.png",
    Title: "Matériel sur sites",
    Description:
      "Notre expertise en géotechnique dans le secteur des machines de forage vous garantit des solutions adaptées et performantes. Grâce à nos techniques avancées de mesure et d'échantillonnage, nous analysons avec précision les caractéristiques des sols. Nous respectons les normes strictes et relevons les défis du chantier pour fournir des résultats fiables. Nos forages verticaux précis et notre équipement de pointe nous permettent d'obtenir des mesures cohérentes. Nous accordons une grande importance à l'interprétation critique des résultats en corrélation avec les données des carottages. Nous prélevons et conservons soigneusement vos échantillons, en utilisant les carotteurs adaptés pour prévenir toute dégradation. Faites confiance à notre expérience pour des projets de forage réussis.",
  },
];

/* Export pour la partie Testemonial */
export const TESTIMONIALS = [
  {
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/TrustImages%2Flogo_eiffage.svg?alt=media&token=a9e4210e-3075-4d38-9b6b-1b07a68b9a5a",
    imgAlt: "Logo EIFFAGE",
  },
  {
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/TrustImages%2Flogo-sweco.png?alt=media&token=20951f40-9525-4ea6-a934-feff9476472e&_gl=1*1ntyozv*_ga*MTg3NTM3Mzk2OS4xNjk3MTIyMTYx*_ga_CW55HF8NVT*MTY5ODgzMzgwNi4xMi4xLjE2OTg4MzM4MTcuNDkuMC4w",
    imgAlt: "Logo SWECO-GROUPE",
  },
  {
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/TrustImages%2Flogo-nge.svg?alt=media&token=d2839a55-55a4-4068-b326-61a844b27c02&_gl=1*1ulvlmf*_ga*MTg3NTM3Mzk2OS4xNjk3MTIyMTYx*_ga_CW55HF8NVT*MTY5ODgyNzEwMS4xMS4xLjE2OTg4Mjc5ODkuMjEuMC4w",
    imgAlt: "Logo NGE",
  },
  {
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/TrustImages%2Flogo-paris-habitat.png?alt=media&token=1b7bcdd1-d653-43b3-9df2-31c474bc313f&_gl=1*1tjzxa*_ga*MTg3NTM3Mzk2OS4xNjk3MTIyMTYx*_ga_CW55HF8NVT*MTY5ODgyNzEwMS4xMS4xLjE2OTg4MjgwMTIuNjAuMC4w",
    imgAlt: "Logo PARIS-HABITAT",
  },
  {
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/TrustImages%2Flogo-societe-du-grand-paris.png?alt=media&token=eeb22f97-215b-4b04-9083-c592f6ec29b8&_gl=1*1qsmab9*_ga*MTg3NTM3Mzk2OS4xNjk3MTIyMTYx*_ga_CW55HF8NVT*MTY5ODgyNzEwMS4xMS4xLjE2OTg4MjgwNTQuMTguMC4w",
    imgAlt: "Logo SOCIETE-DU-GRAND-PARIS",
  },
];
/* Envoie d'Email */
export const EMAIL_CONTACT = "";
