import { useState } from "react";
import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";
import menu from "../assets/menu.png";
import "../css/navbar.css";

const Navbar = ({
  anchorHomeRef,
  anchorAboutUsRef,
  anchorServicesRef,
  anchorMoyensRef,
  anchorTestimonialRef,
  anchorContactUsRef,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <nav
      className={
        isExpanded ? "collapsible nav collapsible--expanded" : "collapsible nav"
      }
    >
      <div className="logo--navbar">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mygeotech-4d.appspot.com/o/GlobaleImages%2Fsol4d_v2_sans_titre.png?alt=media&token=772c473d-5c0f-4498-9840-8163e4f2b56c&_gl=1*129vkl*_ga*MTg3NTM3Mzk2OS4xNjk3MTIyMTYx*_ga_CW55HF8NVT*MTY5ODUxMDgyMi40LjEuMTY5ODUxMDk1NC42MC4wLjA."
          alt="SOL-4D logo"
          width="100"
        />
        <span>SOL 4D</span>
      </div>
      <img
        className="icon icon--primary nav--toggler"
        src={menu}
        alt="menu icon"
        onClick={() => setIsExpanded(!isExpanded)}
      />
      <ul className="collapsible--content list nav--list">
        <li className="nav--item">
          <NavLink
            className="nav--link"
            to="/#accueil"
            onClick={() => {
              if (anchorHomeRef != null && anchorHomeRef.current) {
                anchorHomeRef.current.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Accueil
          </NavLink>
        </li>
        <li className="nav--item">
          <NavLink
            className="nav--link"
            to="/#nous-connaitre"
            onClick={() => {
              if (anchorAboutUsRef != null && anchorAboutUsRef.current) {
                anchorAboutUsRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            À propos
          </NavLink>
        </li>
        <li className="nav--item">
          <NavLink
            className="nav--link"
            to="/#services"
            onClick={() => {
              if (anchorServicesRef != null && anchorServicesRef.current) {
                anchorServicesRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            Services
          </NavLink>
        </li>
        <li className="nav--item">
          <NavLink
            className="nav--link"
            to="/#moyens"
            onClick={() => {
              if (anchorMoyensRef != null && anchorMoyensRef.current) {
                anchorMoyensRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            Moyens
          </NavLink>
        </li>
        <li className="nav--item">
          <NavLink
            className="nav--link"
            to="/#partenaires"
            onClick={() => {
              if (
                anchorTestimonialRef != null &&
                anchorTestimonialRef.current
              ) {
                anchorTestimonialRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            Partenaires
          </NavLink>
        </li>
        <li className="nav--item">
          <NavLink
            className="nav--link"
            to="/#contact"
            onClick={() => {
              if (anchorContactUsRef != null && anchorContactUsRef.current) {
                anchorContactUsRef.current.scrollIntoView({
                  behavior: "smooth",
                });
              }
            }}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
