/* Import CSS Files */
import "../css/aboutUs.css";

/* Import Images */
import aboutUsIcon from "../assets/aboutUs.svg";
import badge from "../assets/badge.svg";
import etude from "../assets/etude.svg";
import iconCb from "../assets/icon-cb.svg";
import iconManServcie from "../assets/icon-man-service.svg";
import iconLoi from "../assets/icon-loi.svg";

const AboutUs = ({ anchorAboutUsRef }) => {
  return (
    <section id="about-us" ref={anchorAboutUsRef} className="block container">
      <header className="block__header" data-aos="fade-up">
        <h2 data-aos="fade-up">
          <strong>SOL 4D, </strong>Qui sommes-nous ?
        </h2>
        <p data-aos="fade-up">
          Passion, expertise et engagement au service de nos clients.
        </p>
      </header>
      <div className="feature grid grid--1x2 grid--ctr-item about__container">
        <div className="feature__content">
          <span className="icon-container" data-aos="fade-up">
            <img src={aboutUsIcon} className="icon " alt="SOL4D-ABOUTUS" />
          </span>
          <h3 data-aos="fade-up">Equipe et expertise</h3>
          <p data-aos="fade-up">
            SOL 4D est un bureau d'études spécialisé dans la réalisation des
            missions géotechniques : G1, G2, G3, G4 et G5, conformément à la
            norme NF P 94 500 et la loi Élan. Notre équipe est à la disposition
            des particuliers , des entreprises et des collectivités pour les
            accompagner dans toutes les étapes de leurs projet de construction.
          </p>
        </div>
        <div className="feature-right-content">
          <ul className="list list--icon">
            <li className="list__item pdt_2" data-aos="fade-up">
              <img
                src={iconManServcie}
                alt="Des ingénieurs experts"
                className="icon icon-list"
              />
              <span>Experts à votre écoute</span>
            </li>
            <li className="list__item pdt_2" data-aos="fade-up">
              <img
                src={etude}
                alt="Une étude clé en main"
                className="icon icon-list"
              />
              <span>Intervention rapide assurée</span>
            </li>
            <li className="list__item pdt_2" data-aos="fade-up">
              <img
                src={iconLoi}
                alt="NFP94500 et loi Elan"
                className="icon icon-list"
              />
              <span>Etude 100% conforme à la loi Élan</span>
            </li>
            <li className="list__item pdt_2" data-aos="fade-up">
              <img
                src={badge}
                alt="NFP94500 et loi Elan"
                className="icon icon-list"
              />
              <span>Missions conformes à NF P 94 500</span>
            </li>
            <li className="list__item pdt_2" data-aos="fade-up">
              <img
                src={iconCb}
                alt="paiement sécurisé"
                className="icon icon-list"
              />
              <span>Paiement en toute sécurité</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
