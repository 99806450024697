import "../css/testimonial.css";
import { TESTIMONIALS } from "../Helper/constants";

const Testimonial = ({ anchorTestimonialRef }) => {
  return (
    <section
      id="testimonial"
      ref={anchorTestimonialRef}
      className="block container"
    >
      <header className="block__header" data-aos="fade-up">
        <h2 data-aos="fade-up">
          Ils nous font <strong>CONFIANCE</strong>
        </h2>
        <p data-aos="fade-up">
          Des partenaires de confiance qui ont choisi notre expertise.
        </p>
      </header>
      <div className="image-container">
        {TESTIMONIALS.map((client, index) => (
          <div key={index} className="image-item" data-aos="fade-up">
            <img
              src={client.imgUrl}
              alt={client.imgAlt}
              data-aos={index % 2 == 0 ? "fade-right" : "fade-left"}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonial;
