import React from "react";
import { NavLink } from "react-router-dom";

const IngenieurieSol = () => {
  return (
    <div className="container row post-page">
      <article className="col-md-12">
        <NavLink to="/" className="link-left-arrow">
          Retourner à l'accueil
        </NavLink>
        <h2 className="post-title mrt-4">Ingénierie des sols</h2>
        <p>
          Bienvenue sur notre page dédiée à l'ingénierie des sols. Nous
          proposons une expertise approfondie dans ce domaine, couvrant quatre
          aspects clés : la création de modèles géologiques, la conception de
          fondations, l'évaluation de la stabilité des talus et la justification
          géotechnique.
          <br />
          <br />
          <b>Modèle géologique :</b> Notre équipe d'ingénieurs spécialisés crée
          des modèles géologiques détaillés pour comprendre la composition et la
          structure du sol dans une zone donnée. En utilisant les données des
          sondages géotechniques et des essais de laboratoire, nous élaborons
          des modèles tridimensionnels qui représentent avec précision les
          couches géologiques, les propriétés des sols et les formations
          rocheuses. Ces modèles géologiques servent de base solide pour
          l'analyse et la conception des projets géotechniques.
          <br />
          <br />
          <b>Conception de fondation :</b> Nous proposons des services de
          conception de fondations adaptés à vos projets de construction. En
          tenant compte des caractéristiques géotechniques du sol et des charges
          appliquées, nos ingénieurs conçoivent des fondations solides et
          durables. Que ce soit pour des bâtiments, des ponts, des structures
          industrielles ou d'autres ouvrages, nous veillons à ce que les
          fondations soient dimensionnées de manière optimale pour assurer la
          stabilité et la sécurité à long terme.
          <br />
          <br />
          <b>Stabilité de talus :</b> L'évaluation et la gestion de la stabilité
          des talus sont des aspects essentiels de notre expertise en ingénierie
          des sols. Nous analysons les pentes naturelles ou artificielles pour
          évaluer les risques de glissement ou d'effondrement. En utilisant des
          méthodes avancées d'analyse géotechnique, nous identifions les
          facteurs de risque, proposons des mesures de stabilisation et
          concevons des ouvrages de soutènement appropriés pour garantir la
          stabilité des talus.
          <br />
          <br />
          <b>Justification géotechnique :</b> Notre équipe fournit une
          justification géotechnique rigoureuse pour soutenir la conception et
          la construction de projets. Nous analysons les données géotechniques,
          évaluons les propriétés du sol et effectuons des études détaillées
          pour évaluer les risques géotechniques potentiels. Nous fournissons
          des rapports techniques complets qui incluent des recommandations
          spécifiques pour la gestion des risques, les mesures de renforcement
          des sols et les paramètres de conception, afin d'assurer la sécurité
          et la stabilité de vos projets.
          <br />
          <br />
          Faites confiance à notre équipe d'ingénieurs expérimentés pour vous
          accompagner dans tous les aspects de l'ingénierie des sols. Nous nous
          engageons à fournir des solutions techniques de haute qualité, en
          utilisant les dernières méthodes et technologies disponibles, pour
          garantir la réussite de vos projets géotechniques.
        </p>
      </article>
    </div>
  );
};

export default IngenieurieSol;
