import "../../css/card.css";

const Card = ({ data }) => {
  return (
    <div key={data.Id}>
      <div className="card-image">
        <div className="star-container">
          <i className="fas fa-crown"></i>
        </div>
        <img src={data.ImageUrl} alt="services" />
        <div className="card-detail">
          <div className="card-title">
            <h3>{data.Title}</h3>
          </div>
          <ul className="list list--tick">
            {data.ShortDescriptions.map((info, index) => (
              <li key={index} className="list__item">
                {info}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Card;
