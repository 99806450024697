import Materials from "./Materials";
import Software from "./Software";

const Moyens = ({ anchorMoyensRef }) => {
  return (
    <section id="moyens" ref={anchorMoyensRef} className="block container">
      <header className="block__header" data-aos="fade-up">
        <h2 data-aos="fade-up">
          Nos differents <strong>MOYENS</strong>
        </h2>
        <p data-aos="fade-up">
          Nous utilisons des technologies adaptées pour votre projet.
        </p>
      </header>
      <Software />
      <Materials />
    </section>
  );
};

export default Moyens;
