/* Import PACKAGES */
import { useRef } from "react";
import { Routes, Route } from "react-router-dom";

/* Import CSS Files */
import "./css/index.css";
import "./css/common/00_typography.css";
import "./css/common/01_badges.css";
import "./css/common/02_lists.css";
import "./css/common/03_buttons.css";
import "./css/common/04_medias.css";
import "./css/common/05_quotes.css";
import "./css/common/06_grids.css";
import "./css/common/07_icons.css";
import "./css/common/08_blocks.css";
import "./css/common/09_article.css";
import "./css/common/10_spaces.css";
import "./css/common/11_post.css";

/* Import JSX files*/
import Navbar from "./components/Navbar";
import TermsOfUse from "./components/TermsOfUse";
import Home from "./components/Home";
import Sondage from "./components/ServicesDetail/Sondage";
import Laboratoire from "./components/ServicesDetail/Laboratoire";
import IngenieurieSol from "./components/ServicesDetail/IngenieurieSol";
import InteractionSolStructure from "./components/ServicesDetail/InteractionSolStructure";
import Footer from "./components/Footer";

export default function App() {
  const anchorHomeRef = useRef(null);
  const anchorAboutUsRef = useRef(null);
  const anchorServicesRef = useRef(null);
  const anchorMoyensRef = useRef(null);
  const anchorTestimonialRef = useRef(null);
  const anchorContactUsRef = useRef(null);

  return (
    <div className="App">
        <header>
          <Navbar
            anchorHomeRef={anchorHomeRef}
            anchorAboutUsRef={anchorAboutUsRef}
            anchorServicesRef={anchorServicesRef}
            anchorMoyensRef={anchorMoyensRef}
            anchorTestimonialRef={anchorTestimonialRef}
            anchorContactUsRef={anchorContactUsRef}
          />
        </header>
        <Routes>
          <Route
            path="/"
            element={<Home
              anchorHomeRef={anchorHomeRef}
              anchorAboutUsRef={anchorAboutUsRef}
              anchorServicesRef={anchorServicesRef}
              anchorMoyensRef={anchorMoyensRef}
              anchorTestimonialRef={anchorTestimonialRef}
              anchorContactUsRef={anchorContactUsRef}
            />}
          />
          <Route
            path="/accueil"
            element={<Home
              anchorHomeRef={anchorHomeRef}
              anchorAboutUsRef={anchorAboutUsRef}
              anchorServicesRef={anchorServicesRef}
              anchorMoyensRef={anchorMoyensRef}
              anchorTestimonialRef={anchorTestimonialRef}
              anchorContactUsRef={anchorContactUsRef}
            />}
          />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/sondage" element={<Sondage />} />
          <Route path="/laboratoire" element={<Laboratoire />} />
          <Route path="/ingenieurie-sol" element={<IngenieurieSol />} />
          <Route path="/interaction-sol-structure" element={<InteractionSolStructure />} />
        </Routes>
        <Footer anchorContactUsRef={anchorContactUsRef} />
    </div>
  );
}
