import SmallCard from "./common/SmallCard";
import { SOFTWARES } from "../Helper/constants";
import software from "../assets/software/software.svg";
const Software = () => {
  return (
    <>
      <header className="block__sub-header">
        <span className="icon-container" data-aos="fade-up">
          <img src={software} className="icon" alt="software" />
        </span>
        <h3 data-aos="fade-up">Moyens Logiciels</h3>
      </header>
      <div className="grid grid--1x4 grid--gap-4 grid--ctr-item">
        {SOFTWARES.map((card, index) => (
          <SmallCard
            key={index}
            title={card.title}
            description={card.description}
            imgUrl={card.imgUrl}
            link={card.link}
          />
        ))}
      </div>
    </>
  );
};

export default Software;
