import React from "react";
import { NavLink } from "react-router-dom";

const Laboratoire = () => {
  return (
    <div className="container row post-page">
      <article className="col-md-12">
        <NavLink to="/" className="link-left-arrow">
          Retourner à l'accueil
        </NavLink>
        <h2 className="post-title mrt-4">Essais de laboratoire</h2>
        <p>
          Bienvenue sur notre page dédiée aux essais de laboratoire. Nous
          proposons une gamme complète d'essais pour analyser les échantillons
          de sol collectés lors de nos sondages géotechniques. Nos essais de
          laboratoire fournissent des informations approfondies sur les
          propriétés des sols, essentielles pour la conception et la
          construction de vos projets géotechniques. Voici quelques-uns des
          essais que nous réalisons :
          <br />
          <br />
          <b>Analyse granulométrique :</b> Nous effectuons une analyse précise
          de la distribution des tailles de particules dans un échantillon de
          sol. Cette analyse permet de déterminer la composition granulaire du
          sol, ce qui est crucial pour évaluer ses propriétés de drainage, de
          compactage et de filtration. Les résultats de l'analyse
          granulométrique sont utilisés dans la conception de remblais, de
          barrages, de routes et d'autres structures.
          <br />
          <br />
          <b>Teneur en eau:</b> La mesure de la teneur en eau est un essai
          fondamental pour évaluer l'état d'un sol. Nous déterminons avec
          précision la quantité d'eau présente dans un échantillon de sol par
          rapport à sa masse totale. Ces données sont utilisées pour calculer
          d'autres paramètres géotechniques tels que l'indice des vides, la
          masse volumique sèche et la teneur en air. La teneur en eau est un
          facteur clé dans la conception des fondations, des structures de
          soutènement et des travaux de terrassement.
          <br />
          <br />
          <b>Essai triaxial :</b> Notre laboratoire est équipé pour réaliser des
          essais triaxiaux avancés sur les échantillons de sol. Cette méthode
          permet d'évaluer le comportement mécanique du sol soumis à des
          contraintes. Les échantillons sont confinés dans une cellule d'essai
          et soumis à des pressions axiales et latérales contrôlées. Les
          résultats de l'essai triaxial nous fournissent des informations
          précieuses sur la résistance au cisaillement, la compressibilité et la
          plasticité du sol, ce qui est essentiel pour la conception de
          structures soumises à des charges et à des déformations importantes.
          <br />
          <br />
          <b>Essai odométrique :</b> Nous réalisons des essais d'odométrie,
          également connus sous le nom d'essais de consolidation, pour évaluer
          le comportement de compression d'un échantillon de sol. Les
          échantillons sont soumis à des charges progressives et nous mesurons
          les déformations verticales qui se produisent au fil du temps. Ces
          données nous permettent de déterminer les caractéristiques de
          consolidation du sol, telles que la compressibilité, la perméabilité
          et le coefficient de consolidation, qui sont essentielles pour prédire
          le tassement du sol sous des charges appliquées.
          <br />
          <br />
          Chez nous, chaque essai de laboratoire est effectué avec précision et
          en conformité avec les normes en vigueur. Nous mettons à votre
          disposition notre expertise et notre équipement de pointe pour vous
          fournir des données fiables et approfondies sur les propriétés des
          sols. Faites confiance à notre laboratoire pour obtenir les
          informations nécessaires à la réussite de vos projets géotechniques.
        </p>
      </article>
    </div>
  );
};

export default Laboratoire;
