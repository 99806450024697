import { useState } from "react";
import { format } from "date-fns";
import emailjs from "emailjs-com";
import { EMAIL_CONTACT } from "../Helper/constants";
import "../css/contactUs.css";

const ContactUs = () => {
  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [Message, setMessage] = useState();
  emailjs.init("OYcd_d8y5_EWKUxsM");

  const handleSendEmail = (e) => {
    var now = new Date();
    var dateNow = format(now, "dd-MM-yyyy HH:mm:ss");
    var body = `Email envoyé le ${dateNow} par M(Mme) ${Name}. Sont adresse email est : ${Email}. Voici son message : ${Message}`;

    // Prepare the email parameters
    const emailParams = {
      to_email: EMAIL_CONTACT,
      from_name: Name,
      reply_to: Email,
      message: body,
    };

    // Send the email
    emailjs
      .send("service_9pq5qba", "template_4e8qct7", emailParams)
      .then((response) => {
        alert("Demande envoyée avec succès");
        resetFields();
      })
      .catch((error) => {
        console.error(
          "Un probléme est survenu veuillez nous contacter par téléphone",
          error
        );
        alert("Un probléme est survenu veuillez nous contacter par téléphone");
      });
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="contact-container">
      <div className="contact-content">
        <div className="left-side">
          <div className="address details">
            <i className="fas fa-map-marker-alt"></i>
            <div className="topic">Adresse</div>
            <div className="text-one">22 Bis rue de Ballainvilliers</div>
            <div className="text-two">91160 Longjumeau</div>
          </div>
          <div className="phone details">
            <i className="fas fa-phone-alt"></i>
            <div className="topic">Téléphone</div>
            <div className="text-one">+33 6 82 05 88 91</div>
            <div className="text-two">+33 7 69 85 34 64</div>
          </div>
          <div className="email details">
            <i className="fas fa-envelope"></i>
            <div className="topic">Email</div>
            <div className="text-one">contact@sol4d.fr</div>
          </div>
        </div>
        <div className="right-side">
          <div className="topic-text">Formulaire de contact</div>
          <p className="information-text">
            Nous sommes ravis de vous aider. Nous nous efforçons de répondre à
            toutes les demandes dans les plus brefs délais.
          </p>
          <form action="#">
            <div className="input-box">
              <input
                type="text"
                placeholder="Entrez votre nom"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="input-box">
              <input
                type="text"
                placeholder="Entez votre adresse email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-box message-box">
              <textarea
                placeholder="Message..."
                value={Message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="button">
              <input type="button" value="Envoyer" onClick={handleSendEmail} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
