import Material from "../assets/materials/material.svg";
import ForageMachine from "../assets/materials/forage.jpeg";
import Laboratory from "../assets/materials/Laboratory-material.jpeg";
import { ReactComponent as SoilIcon } from "../assets/materials/soil-icon.svg";
import { ReactComponent as LaboratoryIcon } from "../assets/materials/laboratory-icon.svg";

import "../css/materials.css";
const Materials = () => {
  return (
    <div className="material-container">
      <header className="block__sub-header">
        <span className="icon-container">
          <img src={Material} className="icon icon--accent" alt="material" />
        </span>
        <h3>Moyens Matériels</h3>
      </header>
      <div className="material-common site-material feature grid grid--1x2 grid--gap-2 grid--ctr-item">
        <div className="feature__content">
          <span>
            <svg className="icon-container icon-svg-accent">
              <SoilIcon className="icon" />
            </svg>
          </span>
          <h3 data-aos="fade-left">Atelier de forage</h3>
          <p data-aos="fade-left">
            Notre expertise en géotechnique dans le secteur des machines de
            forage vous garantit des solutions adaptées et performantes. Grâce à
            nos techniques avancées de mesure et d'échantillonnage, nous
            analysons avec précision les caractéristiques des sols. Nous
            respectons les normes strictes et relevons les défis du chantier
            pour fournir des résultats fiables. Nos forages verticaux précis et
            notre équipement de pointe nous permettent d'obtenir des mesures
            cohérentes. Nous accordons une grande importance à l'interprétation
            critique des résultats en corrélation avec les données des
            carottages. Nous prélevons et conservons soigneusement vos
            échantillons, en utilisant les carotteurs adaptés pour prévenir
            toute dégradation.
          </p>
        </div>
        <div className="feature__image material-image trans_1_1">
          <img src={ForageMachine} alt="Forage machine" />
        </div>
      </div>
      <div className="material-common laboratory-material feature grid grid--1x2 grid--gap-3 grid--ctr-item">
        <div className="feature__content">
          <span>
            <svg className="icon-container icon-svg-accent">
              <LaboratoryIcon className="icon" />
            </svg>
          </span>
          <h3 data-aos="fade-right">Matériels de laboratoire</h3>
          <p data-aos="fade-right">
            Nous disposons d'un ensemble complet de matériels de laboratoire de
            haute qualité pour une analyse approfondie des échantillons
            prélevés. Notre équipement de pointe nous permet d'étudier les
            propriétés physiques et chimiques des échantillons de sol avec une
            grande précision. Nous effectuons des tests rigoureux pour évaluer
            la résistance, la composition et les caractéristiques mécaniques des
            échantillons. Grâce à nos techniques d'analyse avancées, nous
            fournissons des résultats fiables et détaillés pour vous aider à
            prendre des décisions éclairées lors de la planification et de la
            conception de vos projets.
          </p>
        </div>
        <div className="feature__image material-image">
          <img src={Laboratory} alt="Laboratoire" />
        </div>
      </div>
    </div>
  );
};

export default Materials;
