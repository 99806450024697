import { SERVICES } from "../Helper/constants";
import Card from "../components/common/Card";
import { Link } from "react-router-dom";

/* Import CSS Files */
import "../css/services.css";

const Services = ({ anchorServicesRef }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <section
      id="services"
      ref={anchorServicesRef}
      className="block container services__container"
    >
      <header className="block__header">
        <h2 data-aos="fade-up">
          Nos differents <strong>SERVICES</strong> pour vos projets
        </h2>
        <p data-aos="fade-up">
          De l'investigation in-situ au dimensionnement, nous couvrons toutes
          les missions géotechniques.
        </p>
      </header>
      <div className="grid grid--1x4 grid--gap-4 grid--ctr-item">
        {SERVICES.map((service, index) => (
          <Link
            to={"/" + service.Link}
            key={index}
            onClick={scrollToTop}
            data-aos="zoom-in"
          >
            <Card key={index} data={service} />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default Services;
